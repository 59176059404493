import useKrpano from "react-krpano-hooks";
import { useState, useEffect } from "react";
import useScreenOrientation from "react-hook-screen-orientation";
import MenuIcon from "./assets/icons/top-right-white-menu.png";
import CloseIcon from "./assets/icons/top-right-white-close-menu.png";
import LogoIcon from "./assets/icons/logo.png";
import Dashboard from "./components/Dashboard";
import floorImage from "./assets/icons/floor.png";
import floorSecondImage from "./assets/icons/floor-second.png";
import firstFloorIcon from "./assets/icons/first-floor-icon.png";
import secondFloorIcon from "./assets/icons/second-floor-icon.png";
import bluePrint from "./assets/icons/blueprint.png";
import cancelIcon from "./assets/icons/cancel.png";
import pinRed from "./assets/icons/pin-red.png";
import pinVoice from "./assets/icons/pin-voice.png";
import pinCash from "./assets/icons/pin-cash.png";
import pinBar from "./assets/icons/pin-bar.png";
import pinCar from "./assets/icons/pin-car.png";
import pinPoker from "./assets/icons/pin-poker.png";
import pinFood from "./assets/icons/pin-food.png";
import pinMeeting from "./assets/icons/pin-meeting.png";
import rotateDevice from "./assets/icons/rotate-device.png";
import "./App.scss";

const dots = [
  {
    sceneName: "scene_01-outside",
    sceneDescription: "Eingang Casino",
    x: "89",
    y: "51",
    imgSrc: pinRed,
    floor: "first",
  },
  {
    sceneName: "scene_02-limo",
    sceneDescription: "Limo",
    x: "94",
    y: "48",
    imgSrc: pinCar,
    floor: "first",
  },
  {
    sceneName: "scene_03-bigscreen",
    sceneDescription: "Begrüssung vom CEO",
    x: "82",
    y: "51",
    imgSrc: pinVoice,
    floor: "first",
  },
  {
    sceneName: "scene_04-reception",
    sceneDescription: "Empfang",
    x: "73",
    y: "44",
    imgSrc: pinVoice,
    floor: "first",
  },
  {
    sceneName: "scene_05-slot-room-entrance",
    sceneDescription: "Willkomen im Grand Casino Baden",
    x: "54",
    y: "51",
    imgSrc: pinVoice,
    floor: "first",
  },
  {
    sceneName: "scene_06-slot-room-gameplay",
    sceneDescription: "Glücksspielautomat",
    x: "49",
    y: "48.3",
    imgSrc: pinCash,
    floor: "first",
  },
  {
    sceneName: "scene_07-slot-room-enterence-2",
    sceneDescription: "Bar",
    x: "40",
    y: "46.4",
    imgSrc: pinBar,
    floor: "first",
  },
  {
    sceneName: "scene_08-slot-room-bigsy",
    sceneDescription: "Bugsy Bistro & Cocktailbar",
    x: "52",
    y: "43",
    imgSrc: pinBar,
    floor: "first",
  },
  {
    sceneName: "scene_10-slot-room-near-screens-01",
    sceneDescription: "Bühne",
    x: "25",
    y: "43",
    imgSrc: pinRed,
    floor: "first",
  },
  {
    sceneName: "scene_11-slot-room-near-screens-02",
    sceneDescription: "Bühne",
    x: "29",
    y: "48",
    imgSrc: pinRed,
    floor: "first",
  },
  {
    sceneName: "scene_12-slot-room-right-of-grupier",
    sceneDescription: "Eingang Raucherbereich",
    x: "29",
    y: "54.2",
    imgSrc: pinRed,
    floor: "first",
  },
  {
    sceneName: "scene_13-slot-room-right-of-groupier-1",
    sceneDescription: "Craps",
    x: "51.5",
    y: "55",
    imgSrc: pinRed,
    floor: "first",
  },
  {
    sceneName: "scene_14-slot-room-right-of-groupier-2",
    sceneDescription: "Blackjack",
    x: "39",
    y: "64",
    imgSrc: pinRed,
    floor: "first",
  },
  {
    sceneName: "scene_15-slot-room-silicon-doline",
    sceneDescription: "Raucherbereich",
    x: "42.3",
    y: "70",
    imgSrc: pinRed,
    floor: "first",
  },
  {
    sceneName: "scene_16-slot-room-near-groupier-3",
    sceneDescription: "Blackjack",
    x: "52",
    y: "60",
    imgSrc: pinRed,
    floor: "first",
  },
  {
    sceneName: "scene_17-case",
    sceneDescription: "Kasse",
    x: "62",
    y: "61",
    imgSrc: pinRed,
    floor: "first",
  },
  {
    sceneName: "scene_18-case-1",
    sceneDescription: "Pokerraum",
    x: "73",
    y: "61.5",
    imgSrc: pinPoker,
    floor: "first",
  },
  {
    sceneName: "scene_19-elegant-bar",
    sceneDescription: "PLÜ Restaurant",
    x: "40.4",
    y: "17.8",
    imgSrc: pinBar,
    floor: "first",
  },
  {
    sceneName: "scene_20-vip-bar",
    sceneDescription: "PLÜ Restaurant",
    x: "56",
    y: "17.5",
    imgSrc: pinBar,
    floor: "first",
  },
  {
    sceneName: "scene_21-vip-bar-lunch",
    sceneDescription: "PLÜ Restaurant",
    x: "40",
    y: "14",
    imgSrc: pinBar,
    floor: "first",
  },
  {
    sceneName: "scene_22-vip-bar-lunch-2",
    sceneDescription: "PLÜ Restaurant",
    x: "24",
    y: "18",
    imgSrc: pinBar,
    floor: "first",
  },
  {
    sceneName: "scene_23-kitchen",
    sceneDescription: "PLÜ Küche",
    x: "28.5",
    y: "29.5",
    imgSrc: pinFood,
    floor: "first",
  },
  {
    sceneName: "scene_24-meeting-room",
    sceneDescription: "Salon",
    x: "20",
    y: "29.5",
    imgSrc: pinMeeting,
    floor: "first",
  },
  // {
  //   sceneName: "scene_25-restaurant-top",
  //   sceneDescription: "Au Premier 1",
  //   x: "52",
  //   y: "29",
  //   imgSrc: pinMeeting,
  //   floor: "first",
  // },
  {
    sceneName: "scene_26-restaurant-top-2",
    sceneDescription: "Au Premier",
    x: "41",
    y: "29.5",
    imgSrc: pinMeeting,
    floor: "first",
  },
  {
    sceneName: "scene_37-plu-outside",
    sceneDescription: "Eingang PLÜ",
    x: "92",
    y: "24",
    imgSrc: pinRed,
    floor: "first",
  },
  {
    sceneName: "scene_27-down-01",
    sceneDescription: "Glücksspielautomaten",
    x: "9",
    y: "46.9",
    imgSrc: pinRed,
    floor: "second",
  },
  {
    sceneName: "scene_28-down-02",
    sceneDescription: "Glücksspielautomaten",
    x: "9",
    y: "39.4",
    imgSrc: pinRed,
    floor: "second",
  },
  {
    sceneName: "scene_29-down-03",
    sceneDescription: "Glücksspielautomaten",
    x: "12",
    y: "51.3",
    imgSrc: pinRed,
    floor: "second",
  },
  {
    sceneName: "scene_30-down-04",
    sceneDescription: "Glücksspielautomaten",
    x: "18.8",
    y: "51.5",
    imgSrc: pinRed,
    floor: "second",
  },
  {
    sceneName: "scene_31-down-05",
    sceneDescription: "Glücksspielautomaten",
    x: "43.8",
    y: "52.7",
    imgSrc: pinRed,
    floor: "second",
  },
  // {
  //   sceneName: "scene_32-down-06",
  //   sceneDescription: "Glücksspielautomaten",
  //   x: "63.3",
  //   y: "54.5",
  //   imgSrc: pinRed,
  //   floor: "second",
  // },
  {
    sceneName: "scene_33-down-07",
    sceneDescription: "Glücksspielautomaten",
    x: "46.8",
    y: "47.2",
    imgSrc: pinRed,
    floor: "second",
  },
  {
    sceneName: "scene_34-down-08",
    sceneDescription: "Glücksspielautomaten",
    x: "44",
    y: "41.2",
    imgSrc: pinRed,
    floor: "second",
  },
  {
    sceneName: "scene_35-down-09",
    sceneDescription: "Glücksspielautomaten",
    x: "46.5",
    y: "44.7",
    imgSrc: pinRed,
    floor: "second",
  },
  {
    sceneName: "scene_36-down-10",
    sceneDescription: "Glücksspielautomaten",
    x: "44",
    y: "37.3",
    imgSrc: pinRed,
    floor: "second",
  },
];

function App() {
  const [isDashboardVisible, setIsDashboardVisible] = useState(false);
  const [isFloorPlanVisible, setIsFloorPlanVisibe] = useState(false);
  const [floorChooser, setFloorChooser] = useState("first");
  const [currentScene, setCurrenctScene] = useState("");

  const { containerRef } = useKrpano({
    globalFunctions: {
      logNewScene: (scene) => {
        console.log("Scene name: ", scene);
        setCurrenctScene(scene);
      },
    },
  });

  const screenOrientation = useScreenOrientation();

  useEffect(() => {
    if (isFloorPlanVisible && currentScene) {
      const currentPin = document.getElementById(currentScene);
      if (currentPin) {
        currentPin.classList.add("pulse");
      }
    }
  }, [currentScene, isFloorPlanVisible]);

  const dashboard = (
    <div className={`additional-content ${isDashboardVisible ? "active" : ""}`}>
      <div
        className="vr-right-menu"
        onClick={() => setIsDashboardVisible(!isDashboardVisible)}
      >
        <img src={isDashboardVisible ? CloseIcon : MenuIcon} alt="menu icon" />
      </div>
      {isDashboardVisible ? <Dashboard /> : null}
    </div>
  );

  const logo = (
    <a href="http://www.jackpots.ch" target="_blank" rel="noreferrer">
      <img src={LogoIcon} alt="logo-vr" className="logo-img" />
    </a>
  );

  const floarPlan = (
    <div className={`floor-plan ${isFloorPlanVisible ? "visible" : ""}`}>
      <div
        className="floor-plan-icon"
        onClick={() => setIsFloorPlanVisibe(!isFloorPlanVisible)}
      >
        <img
          src={!isFloorPlanVisible ? bluePrint : cancelIcon}
          alt="blueprint"
        />
        {!isFloorPlanVisible ? <span>MAP</span> : null}
      </div>

      {isFloorPlanVisible ? (
        <div className="floor-plan-chooser">
          <div
            className={`floor-plan-icon floor-plan-first ${
              floorChooser === "first" ? "active" : ""
            }`}
            onClick={() => setFloorChooser("first")}
          >
            <img src={firstFloorIcon} alt="first-floor-icon" />
          </div>

          <div
            className={`floor-plan-icon floor-plan-second ${
              floorChooser === "second" ? "active" : ""
            }`}
            onClick={() => setFloorChooser("second")}
          >
            <img src={secondFloorIcon} alt="second-floor-icon" />
          </div>
        </div>
      ) : null}

      {isFloorPlanVisible && floorChooser === "first" ? (
        <div className="floor-plan-container">
          {screenOrientation === "portrait-primary" ||
          screenOrientation === "portrait-secondary" ? (
            <div className="rotate-device">
              <img src={rotateDevice} alt="rotate-device" />
              <p>Rotate device to view map</p>
            </div>
          ) : (
            <>
              <img
                src={floorImage}
                className="floor-plan-image"
                alt="floor-plan"
              />
              {getDots()}
            </>
          )}
        </div>
      ) : isFloorPlanVisible && floorChooser === "second" ? (
        <div className="floor-plan-container">
          {screenOrientation === "portrait-primary" ||
          screenOrientation === "portrait-secondary" ? (
            <div className="rotate-device">
              <img src={rotateDevice} alt="rotate-device" />
              <p>Rotate device to view map</p>
            </div>
          ) : (
            <>
              <img
                src={floorSecondImage}
                className="floor-plan-image"
                alt="floor-plan"
              />
              {getDots()}
            </>
          )}
        </div>
      ) : null}
    </div>
  );

  function redirectToScene(scene) {
    setIsFloorPlanVisibe(false);
    if (scene !== currentScene) {
      const krpano = document.getElementById("krpanoSWFObject");
      krpano.call(`loadscene(${scene},null,MERGE,BLEND(1));`);
    }
  }

  function getDots() {
    return dots
      .filter((x) => x.floor === floorChooser)
      .map((dot) => {
        return (
          <div
            key={dot.sceneName}
            className="dot pulse con-tooltip top"
            style={{ top: `${dot.x}%`, left: `${dot.y}%` }}
            onClick={() => redirectToScene(dot.sceneName)}
          >
            <div className="tooltip">
              <p>{dot.sceneDescription}</p>
            </div>
            <img id={dot.sceneName} src={dot.imgSrc} alt="pin" />
          </div>
        );
      });
  }

  return (
    <div className="App" ref={containerRef}>
      {logo}
      {floarPlan}
      {dashboard}
    </div>
  );
}

export default App;

import { useState, useEffect } from "react";
import useWindowDimensions from "../useWindowDimensions";
import logoSrc from "../../assets/icons/logo.png";
import "./style.scss";


function Dashboard() {
  const [item, setItem] = useState("plu");
  const [iframeHeight, setIframeHeight] = useState('600px');
  const windowDimensions = useWindowDimensions();

  useEffect(() => {
    getContainerHeight();
  }, [windowDimensions.height,windowDimensions.width]);

  function getActiveClass(value) {
    if (item === value) {
      return "active";
    }
    return "";
  }

  function getContainerHeight() {
    setTimeout(() => {
      const element = document.getElementsByClassName('container')[0];
      if (element) {
        setIframeHeight(`${element.clientHeight - 45}px`);
      }
    }, 200);
  }

  function getMainContent() {
    switch (item) {
      case 'plu':
        getContainerHeight();
        return <div><iframe src="https://restaurant.grandcasinobaden.ch/" height={iframeHeight} width="100%" title="Plu" frameBorder="0"/></div>
      case 'jackpots':
        getContainerHeight();
        return <div><iframe src="https://www.grandcasinobaden.ch/" height={iframeHeight}  width="100%" title="Casino" frameBorder="0"/></div>
        case 'jackpotsRadio':
          getContainerHeight();
        return <div><iframe src="https://www.my105.ch/jackpotsradio/" height={iframeHeight}  width="100%" title="Jackpots Radio" frameBorder="0"/></div>
        case 'clubJoy':
          getContainerHeight();
        return <div><iframe src="https://www.clubjoybaden.ch/" height={iframeHeight}  width="100%" title="Club joy" frameBorder="0"/></div>
      default:
        break;
    }
  }

  return (
    <div className="DashboardOverlay">
      <div className="dashboard">
        <div className="head">
          <img className="logo" src={logoSrc} alt="logo" />
        </div>

        <div className="container">
          <div className="left-side">
            <button
              className={getActiveClass("plu")}
              onClick={(e) => setItem(e.target.value)}
              value="plu"
            >
              PLÜ
            </button>
            <button
              className={getActiveClass("jackpots")}
              onClick={(e) => setItem(e.target.value)}
              value="jackpots"
            >
              Casino
            </button>
            <button
              className={getActiveClass("jackpotsRadio")}
              onClick={(e) => setItem(e.target.value)}
              value="jackpotsRadio"
            >
              Jackpots Radio
            </button>
            <button
              className={getActiveClass("clubJoy")}
              onClick={(e) => setItem(e.target.value)}
              value="clubJoy"
            >
              Club Joy
            </button>
          </div>
          <div className="content">{getMainContent()}</div>
        </div>
      </div>
    </div>
  );
}


export default Dashboard;

